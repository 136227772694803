import {Card, Page} from '@shopify/polaris-internal';
import {useI18n} from '@shopify/react-i18n';
import React from 'react';

import {PageFooterHelp, SkeletonTable} from '~/components';
import {createI18nConfig} from '~/utils/createI18nConfig';
import {filtersURL} from '~/utils/urls';

import translations from './translations';

export function FiltersListSkeleton() {
  const [i18n] = useI18n(createI18nConfig(translations));

  return (
    <Page
      title={i18n.translate('FiltersList.title')}
      primaryAction={{
        content: i18n.translate('FiltersList.actions.addFilter'),
        url: filtersURL('new'),
      }}
    >
      <Card padding="0">
        <SkeletonTable numColumns={3} selectable={false} />
      </Card>

      <PageFooterHelp resourceName="filters" />
    </Page>
  );
}
